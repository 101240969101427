import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


export const RecaptchaProvider= props =>{    
  // const keyRecaptcha = '6LcheYsoAAAAADTI8-Yk67bhGjmgjwgIpHymtglf' // Publisher key

    return(
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeaXbgqAAAAAFnPXEcVY6IwNloDJ1mdX6BGndSb"
            scriptProps={{
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            }}
        >
            {props.children}
        </GoogleReCaptchaProvider>
    )

}
